<script setup>
import { useI18n } from "vue-i18n";
import {mdiTranslate} from "@mdi/js";

const i18n = useI18n()

</script>

<template>
  <v-menu>
    <template #activator="{ props }">
      <v-btn
        color="primary"
        v-bind="props"
      >
        <v-icon :icon="mdiTranslate" />
        {{ i18n.t('general.language') }}
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in i18n.locales.value"
        :key="index"
        :value="index"
        :to="switchLocalePath(item.code)"
      >
        <v-list-item-title>
          {{
            item.name
          }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
