<script setup>
import {useI18n} from "vue-i18n";
import { useLocalePath} from "#i18n";
import { mdiHome, mdiEarthBox, mdiInformation, mdiTranslate } from "@mdi/js";

const i18n = useI18n()
const localePath = useLocalePath()

const drawer = ref(false);
</script>
<script>
export default { name: "PageHeader" };
</script>
<template>
  <v-app-bar
    scroll-behavior="elevate"
    color="white"
  >
    <v-app-bar-nav-icon
      class="d-md-none"
      :aria-label="i18n.t('navigation.toggleNavigationDrawer')"
      @click.stop="drawer = !drawer"
    />

    <NuxtLink
      :to="localePath('/')"
      class="d-flex text-decoration-none display-3 text-primary align-items-center ml-8"
    >
      <img
        src="/climatespy.svg"
        height="50"
        class="mr-5 d-none d-md-block"
        alt="climatespy.com logo"
      >

      climate<strong>Spy</strong>.com
    </NuxtLink>

    <template #append>
      <div
        class="d-none d-md-inline-block"
      >
        <v-btn
          class="text-blue-darken-3"
          :to="localePath('/')"
        >
          <v-icon :icon="mdiHome" />
          {{ i18n.t('navigation.home') }}
        </v-btn>
        <v-btn
          class="text-blue-darken-3"
          :to="localePath('/places/dir/united-states/')"
        >
          <v-icon :icon="mdiEarthBox" />
          {{ i18n.t('navigation.places') }}
        </v-btn>
        <v-btn
          class="text-blue-darken-3"
          :to="localePath('/about')"
        >
          <v-icon :icon="mdiInformation" />
          {{ i18n.t('navigation.about') }}
        </v-btn>
        <LanguageSelector />
      </div>
    </template>
  </v-app-bar>
  <v-navigation-drawer
    v-model="drawer"
    :temporary="true"
    location="bottom"
    style="height: 91%"
  >
    <v-list
      :aria-label="i18n.t('navigation.siteNavigation')"
      role="list"
    >
      <v-list-item :to="localePath('/')">
        <v-list-item-title>
          <v-icon :icon="mdiHome" />
          {{ i18n.t('navigation.home') }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item :to="localePath('/places/dir/united-states/')">
        <v-list-item-title>
          <v-icon :icon="mdiEarthBox" />
          {{ i18n.t('navigation.places') }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item :to="localePath('/about')">
        <v-list-item-title>
          <v-icon :icon="mdiInformation" />
          {{ i18n.t('navigation.about') }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-list-subheader>
          <v-icon :icon="mdiTranslate" />
          {{ i18n.t('general.language') }}
        </v-list-subheader>
      </v-list-item>
      <v-list-item
        v-for="(item, index) in i18n.locales.value"
        :key="index"
        :value="index"
        :to="switchLocalePath(item.code)"
      >
        <v-list-item-title>
          {{
            item.name
          }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
